import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../styles/bonusCalculation.css";
import ScoreBonus from "../../../organisms/ScoreBonus";
import {
  getComponentsByScenarioThunk,
  getComponentsScenarioEmployee,
} from "../../../infrastructure/redux/features/componentsSlice";
import {
  clearEmployeeList,
  getEmployees,
} from "../../../infrastructure/redux/features/employeeSlice";
import {
  useTypedSelector,
  useTypedDispatch,
} from "../../../infrastructure/redux/store";
import PageTitle from "./PageTitle";
import BonusPreferences from "../../../organisms/BonusPreferences";
import LoaderContainer from "../Loader/LoaderContainer";
import {
  clearBonusResults,
  getGeneralBonusAvailableThunk,
} from "../../../infrastructure/redux/features/scenarioSlice";
import {
  checkLoaderContainerStatus,
  onNotify,
} from "../../helpers/notification-utils";

export const BonusCalculation = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCsvModalOpen, setIsCsvModalOpen] = useState<boolean>(false); // uncomment lint blocker when implement modal
  const dispatch = useTypedDispatch();

  const generalBonusAvaliableLoadingStatus = useTypedSelector(
    (state) => state.scenarioReducer.status,
  );
  const generalBonusAvaliable = useTypedSelector(
    (state) => state.scenarioReducer.generalBonusAvailable,
  );
  const currentScenario = useTypedSelector(
    (state) => state.scenarioReducer.scenario,
  );
  const employeeList = useTypedSelector(
    (state) => state.employeeReducer.employees,
  );
  const componentsByScenario = useTypedSelector(
    (state) => state.componentReducer.componentsByScenario,
  );
  const components = useTypedSelector(
    (state) => state.componentReducer.components,
  );

  useEffect(() => {
    if (currentScenario != null) {
      dispatch(clearBonusResults());
      dispatch(clearEmployeeList());
      void dispatch(getComponentsByScenarioThunk(currentScenario.id));
      void dispatch(getComponentsScenarioEmployee(currentScenario.id));
    }
  }, [currentScenario, dispatch]);

  useEffect(() => {
    if (employeeList.length <= 0 && currentScenario != null) {
      void dispatch(getEmployees(currentScenario.id));
    }
  }, [currentScenario, dispatch, employeeList]);

  function onGetBonusCalculation() {
    if (currentScenario != null) {
      void dispatch(
        getGeneralBonusAvailableThunk({
          scenarioId: Number(currentScenario?.id),
          performanceBonus: Number(currentScenario?.performanceBonus),
          payrollProrated: Number(currentScenario?.payrollProrated),
        }),
      );
    }
  }

  const handleOpenCsvModal = () => {
    setIsCsvModalOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCloseCsvModal = () => setIsCsvModalOpen(false); // uncomment lint blocker when implement modal

  return (
    <LoaderContainer
      conditions={checkLoaderContainerStatus(
        generalBonusAvaliableLoadingStatus,
      )}
      loadingMessage="Calculating..."
    >
      <Container
        className="abs-center mt-5 position-relative"
        data-testid="BonusCalculation"
      >
        <PageTitle
          onGetBonusCalculation={onGetBonusCalculation}
          handleOpenCsvModal={handleOpenCsvModal}
        />
        <BonusPreferences
          generalBonusAvailable={generalBonusAvaliable?.general_bonus_total}
          onNotify={onNotify}
        />
        <ScoreBonus
          employeeList={employeeList}
          componentsByScenario={componentsByScenario}
          allComponents={components}
          bonusData={generalBonusAvaliable?.bonus_calculation ?? []}
        />
      </Container>
    </LoaderContainer>
  );
};

export default BonusCalculation;
