import { useNavigate } from "react-router-dom";
import Button from "../../../atoms/Button";
import {
  actionIfAuthenticated,
  navigateIfAuthenticated,
} from "../../../utils/authChecker";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface Props {
  onGetBonusCalculation: () => void;
  handleOpenCsvModal: () => void;
}

const PageTitle = ({ onGetBonusCalculation, handleOpenCsvModal }: Props) => {
  const navigate = useNavigate();

  return (
    <div data-testid="PageTitle">
      <div className="titleMargin">
        <h1>Bonus results</h1>
        <div className="button-container">
          <Button
            dataTestid="uploadCsv"
            className="button-primary-green"
            onClick={handleOpenCsvModal}
            startIcon={<CloudUploadIcon />}
          >
            Upload CSV
          </Button>{" "}
          <Button
            dataTestid="listComponents"
            className="button-primary-orange"
            onClick={() => {
              void (async () => {
                await navigateIfAuthenticated("/component-detail", navigate);
              })();
            }}
          >
            List Components
          </Button>{" "}
          <Button
            dataTestid="listEmployees"
            className="button-primary-orange margin-left-button"
            onClick={() => {
              void (async () => {
                await navigateIfAuthenticated("/employee-detail", navigate);
              })();
            }}
          >
            List Employees
          </Button>{" "}
          <Button
            dataTestid="getBonusCalculation"
            className="button-primary-orange margin-left-button"
            onClick={() => {
              void (async () => {
                await actionIfAuthenticated({
                  myFunction: onGetBonusCalculation,
                  myNavigate: navigate,
                });
              })();
            }}
          >
            Calculate
          </Button>{" "}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default PageTitle;
